import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  ETemplatesTypes,
  resetSelectedTemplateData,
  selectTemplateData,
  selectTemplatesTab,
  setSelectedTemplateData,
  templatesTypeConverter,
} from '../../pages/templates/lib/templatesSlice';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { MoreVertical20 } from '../../icons/MoreVertical';
import { AppDropdown } from '../../shared/AppDropdown/AppDropdown';
import TrashIcon from '../../icons/TrashIcon';
import { DuplicateIcon20 } from '../../icons/DuplicateIcon';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import { ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { formateValueForLastActive } from '../../routes-old/process/helpers';
import { FieldSectionDTO, ProcessStageDTO } from '../../../shared/Field/Field';
import {
  TemplateBottomWrapper,
  TemplateContentContainer,
  TemplateTabItem,
  TemplateTabList,
  TemplatesTabPanel,
  TemplateSubtitle,
  TemplateTitle,
  TemplateUpperWrapper,
} from '../../pages/templates/Styles/TemplatesStyles';
import useTemplates from '../../hooks/useTemplates';
import { NoContentBalloonPlaceholder } from '../../shared/NoContentBalloonPlaceholder/NoContentBalloonPlaceholder';
import TemplateTabItemSkeleton from './TemplateMenuItemSkeleton';

interface Props {
  tabValue: ETemplatesTypes;
  templates?: ProcessDTO[] | ProcessStageDTO[] | FieldSectionDTO[];
  isSuccess?: boolean;
}

const TemplateTab = ({ tabValue, templates, isSuccess }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const currentTab = useAppSelector(selectTemplatesTab);
  const selectedTemplateData = useAppSelector(selectTemplateData);
  const [selectedTemplate, setSelectedTemplate] = useState<{ id: string, title: string } | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { onTemplateCopy, onTemplateDelete } = useTemplates();

  const onTemplateSelect = (id: string, title: string) => {
    dispatch(setSelectedTemplateData({ id, title, type: templatesTypeConverter[currentTab] }));
  };

  const onDeleteDialogAccept = (choice: boolean) => {
    if (templates && choice && !!selectedTemplate) {
      onTemplateDelete(selectedTemplate.id);
      if (
        selectedTemplateData?.id === selectedTemplate.id
      ) {
        const newTemplate = templates.find((template) => template.id !== selectedTemplate.id);
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: newTemplate.title,
            type: templatesTypeConverter[currentTab],
          }));
        }
      }
    }
    setSelectedTemplate(null);
    setOpen(false);
  };

  useEffect(() => {
    if (
      templates?.length
      && selectedTemplateData?.type !== templatesTypeConverter[currentTab]
      && currentTab === tabValue
      && !isMobile
    ) {
      dispatch(setSelectedTemplateData({
        id: templates[0].id,
        title: templates[0].title ?? '',
        type: templatesTypeConverter[currentTab],
      }));
    }
    if (
      !templates?.length
      && currentTab === tabValue
      && selectedTemplateData?.type !== templatesTypeConverter[currentTab]
      && isSuccess
    ) {
      dispatch(resetSelectedTemplateData());
    }
  }, [templates, currentTab, selectedTemplateData?.type, isMobile, isSuccess]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicateBtnClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    if (!selectedTemplate) return;
    onTemplateCopy(selectedTemplate.id, selectedTemplate.title);
    handleClose();
  };

  const handleDeleteBtnClick = (event: React.BaseSyntheticEvent) => {
    event.stopPropagation();
    setOpen(true);
    handleClose();
  };

  const handleMenuBtnClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    title: string,
  ) => {
    event.stopPropagation();
    setSelectedTemplate({ id, title });
    setAnchorEl(event.currentTarget);
  };

  if (currentTab !== tabValue) return null;

  if (!isSuccess) {
    return (
      <TemplatesTabPanel
        value={tabValue}
      >
        <TemplateTabList
          className={isMobile ? 'template-menu-list-mobile' : undefined}
        >
          <TemplateTabItemSkeleton index={0} />
          <TemplateTabItemSkeleton index={1} />
          <TemplateTabItemSkeleton index={2} />
        </TemplateTabList>
      </TemplatesTabPanel>
    );
  }

  if (!templates || !templates.length) {
    return (
      <NoContentBalloonPlaceholder description="Nothing here yet" />
    );
  }

  return (
    <TemplatesTabPanel
      value={tabValue}
    >
      <TemplateTabList
        className={isMobile ? 'template-menu-list-mobile' : undefined}
      >
        {templates.map((template, index) => (
          <TemplateTabItem
            key={index}
            className={classNames(
              { 'template-item-selected': template.id === selectedTemplateData?.id },
              { 'template-item-mobile': isMobile },
            )}
            disableRipple
            onClick={() => onTemplateSelect(template.id, template.title ?? '')}
          >
            <TemplateContentContainer
              className={isMobile ? 'template-content-container-mobile' : undefined}
            >
              <TemplateUpperWrapper
                className={isMobile ? 'template-upper-wrapper-mobile' : undefined}
              >
                <TemplateTitle>
                  {template.title}
                </TemplateTitle>
                {isMobile && (
                  <AppIconButton
                    icon={<MoreVertical20 />}
                    onClick={(event) => handleMenuBtnClick(event, template.id, template.title ?? '')}
                  />
                )}
              </TemplateUpperWrapper>
              <TemplateBottomWrapper
                className={isMobile ? 'template-bottom-wrapper-mobile' : undefined}
              >
                <TemplateSubtitle
                  className={isMobile ? 'template-mobile-description-typography' : undefined}
                >
                  Updated
                </TemplateSubtitle>
                <TemplateSubtitle
                  className={isMobile ? 'template-mobile-time-typography' : undefined}
                >
                  {formateValueForLastActive(DateTime.fromISO(template.lastInteraction || template.updatedAt).toMillis() as number)}
                </TemplateSubtitle>
              </TemplateBottomWrapper>
            </TemplateContentContainer>
            {!isMobile && (
              <AppIconButton
                size="l"
                icon={<MoreVertical20 />}
                onClick={(event) => handleMenuBtnClick(event, template.id, template.title ?? '')}
              />
            )}
            <AppDropdown
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClick={(e) => e.stopPropagation()}
              onClose={() => handleClose()}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              lastElementHasAnotherColor
              items={[
                {
                  label: 'duplicate',
                  onClick: handleDuplicateBtnClick,
                  startIcon: <DuplicateIcon20 />,
                },
                {
                  label: '',
                  divider: true,
                },
                {
                  label: 'Delete',
                  onClick: handleDeleteBtnClick,
                  startIcon: <TrashIcon />,
                },
              ]}
            />

          </TemplateTabItem>
        ))}
        <ConfirmationDialog
          icon={<DeleTeIcon />}
          titleVariant="danger-primary"
          isDialogOpen={open}
          onConfirm={() => onDeleteDialogAccept(true)}
          onCancel={() => onDeleteDialogAccept(false)}
          dialogContentText={(
            <Box>
              Are you sure you want to delete template?
            </Box>
              )}
          dialogTitle={selectedTemplate?.title ?? 'Delete section template'}
          confirmText="Delete"
        />
      </TemplateTabList>
    </TemplatesTabPanel>
  );
};

export default TemplateTab;
