import { Box, styled, Typography } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const IntegrationTabCard = styled(Box)(() => ({
  width: '100%',
  padding: '24px',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-around',
  flexDirection: 'column',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.white[100],
  borderRadius: '20px',
  gap: '8px',
  boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04), '
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07), '
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08), '
    + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
}));

export const IntegrationHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const IntegrationTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  maxWidth: '45px',
  gap: '12px',
}));

export const IntegrationHeaderTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '26px',
  color: stagesetColors.newGrey[800],
}));

export const IntegrationDescription = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const IntegrarionStatusText = styled(Typography)(() => ({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '16px',
  color: stagesetColors.green[600],
}));

export const IntegrationButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
}));

export const IntegrationButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '8px',
}));

export const IntegrationsTabGridWrapper = styled(Box)(() => ({
  width: '100%',
  maxWidth: '1080px',
}));

export const IntegrationsTabGrid = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, max(250px, (100%/2 - 20px))), 1fr))',
  gap: '24px',
  justifyContent: 'center',
  '&.integrations-tab-grid-container-mobile': {
    gap: '12px',
  },
}));
