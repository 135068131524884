import { TextFieldProps } from '@mui/material';
import classNames from 'classnames';
import { StyledAppInput } from './AppInputStyles';

export type AppInputSize = 'xs' | 's' | 'm' | 'l' | 'l-heading-font';

export type AppInputVariant = 'transparent' | 'standard' | 'outlined' | 'lite' | 'lite-grey' | 'themed';

export type AppInputHelperTextPlacement = 'end' | 'start';

export type AppInputMarginBottom = string;

export type AppInputProps = {
  size?: AppInputSize
  variant?: AppInputVariant
  marginBottom?: AppInputMarginBottom
  helperTextPlacement?: AppInputHelperTextPlacement
} & Omit<TextFieldProps, 'size' | 'variant'>;

export const AppInput = ({
  disabled,
  size = 'm',
  variant = 'transparent',
  marginBottom,
  helperTextPlacement = 'start',
  className,
  ...inputProps
}: AppInputProps) => (
  <StyledAppInput
    {...inputProps}
    disabled={disabled}
    className={
      classNames(
        `size-${size}`,
        `variant-${variant}`,
        `helper-text-placement-${helperTextPlacement}`,
        { disabled, multiline: inputProps.multiline },
        className,
      )
    }
    sx={{
      marginBottom,
    }}
  />
);
